<template>
    <div class="flex flex-col">
        <div class="mx-auto px-4 flex flex-col w-full">
            <div id="fb-root"></div>

          <button class="btn button-facebook" v-if="!this.player.isFacebook" @click="onClickFacebook">
            <img class="img-svg" src="@/assets/facebook.svg" alt="Login with facebook" />
            <div class="fbText">
              <div class="big">FACEBOOK</div> {{ this.player.isEmail && this.player.isVerification ? "HESABINI BAĞLA" : "ile GİRİŞ YAP" }}
            </div>
            <div class="social-login-reward">+ <img src="@/assets/coin-small.svg"> 500 *</div>
          </button>

          <button class="btn button-facebook" @click="removeFacebookConnection" v-else>
            <img class="img-svg" src="@/assets/facebook.svg" alt="Disconnect facebook" />
            <div class="fbText">
              <div class="big">FACEBOOK</div> HESABIN BAĞLI
            </div>
          </button>

          <p class="w-full text-center my-4 font-bold">VEYA</p>


            <!-- <div class="flex social-login-container"> -->
              <button href="#" class="btn button-google" v-if="!this.player.isGoogle" @click="onClickGoogle">

                <img class="img-svg" src="@/assets/google.svg" alt="Login with google">
                <div class="gText">
                    <div class="big">GOOGLE</div> {{ this.player.isEmail && this.player.isVerification ? "HESABINI BAĞLA" : "ile GİRİŞ YAP" }}
                </div>

                <div class="social-login-reward">+ <img src="@/assets/coin-small.svg"> 500 *</div>
              </button>

              <button href="#" class="btn button-google" @click="removeGoogleConnection" v-else>
                  <img class="img-svg" src="@/assets/google.svg" alt="Disconnect google">
                  <div class="gText">
                      <div class="big">GOOGLE</div> HESABIN BAĞLI
                  </div>
              </button>

          <p class="w-full text-center my-4 font-bold">VEYA</p>


          <template v-if="player.isGuest">
            <button class="btn btn--primary btn--sm button-login mb-5" v-if="!this.player.isEmail" @click="clickLoginModal">
              <div class="bText">
                <div class="big">GİRİŞ YAP</div>
              </div>
              <div class="social-login-reward">+ <img src="@/assets/coin-small.svg"> 200 *</div>
            </button>
          </template>

              <!--button class="btn button-apple">
                  <img class="img-svg" src="@/assets/apple.svg" alt="Login with apple" />
                  <div class="fbText">
                      <div class="big">APPLE</div> ile GİRİŞ YAP
                  </div>
                  <div class="social-login-reward">+ <img src="@/assets/coin-small.svg"> 500 *</div>
              </button-->

              <button class="btn btn--primary button-login relative mb-5" v-if="this.isAuthenticated && !this.player.isGuest" @click="handleLogout">
                  <div class="bText">
                      ÇIKIŞ YAP
                  </div>
              </button>
            <!-- </div> -->
        </div>

        <p class="text-center mb-4 mt-5 text-xs" v-if="!this.player.isGoogle || !this.player.isFacebook">* Ödül hesabınıza ilk girişinizde tanımlanacaktır. </p>
    </div>
</template>

<script>

import {mapActions, mapGetters, mapState} from "vuex";

export default {
    name: "login-box",
    data() {
        return {
            loading: false,
            loginType: null,
            loginData: {
                username: null,
                password: null
            }
        }
    },
    computed: {
        ...mapState(["player"]),
        ...mapGetters(["isAuthenticated"])
    },
    methods: {
        ...mapActions(["getPlayerInfo", "googleLogin", "facebookLogin", "clearGameData", "login", "register", "logout", "init", "disconnectPlatform"]),
        clickLoginModal() {
            this.$modal.show("login-and-register-modal")
        },
        removeGoogleConnection() {
          if (!this.player.isGoogle) {
            this.$toast.warning('Hesabınız zaten bağlı değil.');
            return false;
          }

          this.$modal.show('dialog', {
            class: "modal px-6 py-10",
            title: `Google bağlantını sonlandırmak istediğine emin misin ?`,
            buttons: [{
                title: "Evet",
                class: "btn btn--primary btn--sm mr-4",
                handler: () => {
                  this.$gAuth
                    .signOut()
                    .then(() => {
                        this.disconnectPlatform({
                            platform: 'google'
                        }).then(() => {
                            window.location.reload();
                        }).catch(error => {
                            this.$toast.error(error.response.data.message);
                            this.$modal.hide('dialog')
                        });
                    })
                }
              },
              {
                title: "Hayır",
                class: "btn btn--secondary btn--sm",
                handler: () => {
                  this.$modal.hide('dialog')
                }
              }
            ]
          });
        },
        onClickGoogle() {
            if (this.player.isGoogle) {
                this.$toast.warning('Hesabınız daha önce bağlandı');
                return false;
            }
            
            this.$gAuth
                .signIn()
                .then(GoogleUser => {
                    this.googleLogin({
                        "token": GoogleUser.getAuthResponse().id_token,
                        "access": GoogleUser.getAuthResponse().access_token
                    }).then(() => {
                        window.location.reload();
                    }).catch(error => {
                        if(error.response && error.response.data &&  error.response.data.code == "401.0011") {
                          this.$modal.show("error-modal", {content: error.response.data && error.response.data.message ? error.response.data.message : "Bilinmeyen bir hata oluştu. Hata tekrar ederse destek ekibimizle iletişime geçebilirsiniz.", issue: "support"});
                        }
                    });
                })
                .catch(error => {
                    console.log('error', error)
                })
        },
        removeFacebookConnection() {
            if (!this.player.isFacebook) {
              this.$toast.warning('Hesabınız zaten bağlı değil.');
              return false;
            }

            this.$modal.show('dialog', {
              class: "modal px-6 py-10",
              title: `Facebook bağlantını sonlandırmak istediğine emin misin ?`,
              buttons: [{
                  title: "Evet",
                  class: "btn btn--primary btn--sm mr-4",
                  handler: () => {
                     this.disconnectPlatform({
                        platform: 'facebook'
                      }).then(() => {
                          window.location.reload();
                      }).catch(error => {
                          this.$toast.error(error.response.data.message);
                          this.$modal.hide('dialog')
                      });
                  }
                },
                {
                  title: "Hayır",
                  class: "btn btn--secondary btn--sm",
                  handler: () => {
                    this.$modal.hide('dialog')
                  }
                }
              ]
            });
        },
        onClickFacebook() {
            if (this.player.isFacebook) {
                this.$toast.warning('Hesabınız daha önce bağlandı');
                return false;
            }

            this.$fAuth.logInWithFacebook().then(response => {
                if (response.authResponse.accessToken && response.authResponse.userID) {
                    this.facebookLogin({
                        token: response.authResponse.accessToken,
                        userID: response.authResponse.userID
                    }).then(() => {
                        window.location.reload();
                    }).catch(error => {
                        if(error.response && error.response.data &&  error.response.data.code == "401.0011") {
                          this.$modal.show("error-modal", {content: error.response.data && error.response.data.message ? error.response.data.message : "Bilinmeyen bir hata oluştu. Hata tekrar ederse destek ekibimizle iletişime geçebilirsiniz.", issue: "support"});
                        }
                    });
                }
            });
        },

        handleLogout() {
          
          this.$modal.show('dialog', {
            class: "modal px-6 py-10",
            title: `Oturumunu sonlandırmak istediğine emin misin ?`,
            buttons: [{
                title: "Evet",
                class: "btn btn--primary btn--sm mr-4",
                handler: () => {
                  this.logout().then(() => {
                    this.$modal.hide('dialog')
                    window.location.reload();
                  }).catch(error => {
                    this.$toast.error(error.response.data.message);
                    this.$modal.hide('dialog')
                  });
                }
              },
              {
                title: "Hayır",
                class: "btn btn--secondary btn--sm",
                handler: () => {
                  this.$modal.hide('dialog')
                }
              }
            ]
          });
            
        }

    }
}
</script>